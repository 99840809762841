<template>
  <div>
    <div class="vx-row">
      <vx-card :noShadow="true" :cardBorder="true">
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="infos générales" headerBackground="#c1c1c1">
              <!-- Content Row -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-select autocomplete v-model="Civilite" class="w-full select-large mt-4" label="Civilité">
                    <vs-select-item :key="index" :value="item.text" :text="item.value" v-for="(item,index) in civilites" class="w-full" />
                  </vs-select>

                  <vs-input class="w-full mt-4" label="Nom (*)" v-model="NomPersonel" v-validate="'required|alpha_spaces'" name="nom" />
                  <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>

                  <vs-input class="w-full mt-4" label="Prénoms (*)" v-model="PrenomPersonel" v-validate="'required|alpha_spaces'" name="prenoms" />
                  <span class="text-danger text-sm" v-show="errors.has('prenoms')">{{ errors.first('prenoms') }}</span>

                  <vs-input class="w-full mt-4" label="Alias" v-model="AliasPersonel" name="alias" />

                  <div class="mt-4">
                    <label class="text-sm">Date de naissance</label>
                    <flat-pickr v-model="DatNaisPersonel" :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" v-validate="'required'" name="naissance" />
                    <span class="text-danger text-sm"  v-show="errors.has('naissance')">{{ errors.first('naissance') }}</span>
                  </div>

                </div>
              </div>
            </vx-card>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <vx-card :noShadow="true" :cardBorder="true" title="Officine - Adresses - Poste">
              <!-- Content Row -->
              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-select autocomplete v-model="IdOfficine" class="w-full select-large mt-4" label="Officine">
                    <vs-select-item :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" class="w-full" />
                  </vs-select>
                  <vs-input class="w-full mt-4" label="Poste" v-model="PostPersonel" name="poste" />
                  <vs-input class="w-full mt-4" label="Email (*)" v-model="EmailPersonel" type="email" v-validate="'required|email'" name="email" />
                  <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
                  <vs-input class="w-full mt-4" label="Adresse" v-model="AdrPersonel" name="adresse" />
                  <vs-input class="w-full mt-4" label="Telephone (*)" v-model="TelPersonel" v-validate="'required'" name="tel" />
                  <span class="text-danger text-sm" v-show="errors.has('tel')">{{ errors.first('tel') }}</span>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-8 flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="savePersonnel">Enregistrer</vs-button>
              <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Annuler</vs-button>
            </div>
          </div>
        </div>

      </vx-card>
    </div>
    <vx-card :noShadow="true" :cardBorder="true" title="Infos CNI" headerBackground="#c1c1c1" class="mt-5">
      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <personnel-image-upload v-if="PersonnelId" limit="1" text="Photo" :url="buildSendPhotoUrl" @on-success="successUpload" />
          <div class="mt-4">
            <vs-select v-model="PersonnelId" class="w-full select-large mt-4" label="Personnel">
              <vs-select-item :key="index" :value="item.id" :text="item.personnelFullName" v-for="(item,index) in Personnels" class="w-full" />
            </vs-select>
          </div>
          <vs-input class="w-full mt-4" label="Numero CNI" v-model="NumCniPersonel" name="numero_CNI" />
          <div class="mt-4">
            <label class="text-sm">Date émission</label>
            <flat-pickr v-model="DateEmmissionCniPersonel" :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" name="DateEmmissionCniPersonel" />
          </div>
          <div class="mt-4">
            <label class="text-sm">Date expiration</label>
            <flat-pickr v-model="DateExpireCniPersonel" class="w-full" name="DateExpireCniPersonel" />
          </div>
        </div>
        <div v-if="PersonnelId" class="vx-col md:w-1/2 w-full">
          <personnel-image-upload v-if="PersonnelId" limit="2" text="SCAN CNI" :url="buildSendScanCniUrl" @on-success="successUpload" />
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="addCNIInfo">Enregistrer</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Annuler</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import PersonnelImageUpload from './../component/PersonnelImageUpload.vue'

export default {
  name: 'personnelNouveau',
  components: {
    vSelect,
    flatPickr,
    PersonnelImageUpload
  },
  data () {
    return {
      IdOfficine: null,
      Civilite: '',
      NomPersonel: '',
      PrenomPersonel: '',
      AliasPersonel: '',
      DatNaisPersonel: '',
      PostPersonel: '',
      TelPersonel: '',
      AdrPersonel: '',
      EmailPersonel: '',
      photo: '',
      NumCniPersonel: '',
      CniScanPersonel: '',
      DateEmmissionCniPersonel: '',
      DateExpireCniPersonel: '',
      Personnels: [],
      transiteObje: null,
      PersonnelId: '',
      civilites: [
        {
          text: 'Prof',
          value: 'Prof'
        },
        {
          text: 'Dr',
          value: 'Dr'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'Mme',
          value: 'Mme'
        },
        {
          text: 'Mlle',
          value: 'Mlle'
        }
      ]
    }
  },
  computed: {
    buildSendPhotoUrl () {
      return `/api/personnel/add-photo/${this.PersonnelId}`
    },
    buildSendScanCniUrl () {
      return `/api/personnel/add-scan-cni/${this.PersonnelId}`
    },
    officines () {
      return this.$store.state.officine.officines
    },
    personnels () {
      return this.$store.state.personnel.personnels
    }
  },
  methods: {
    savePersonnel () {
      const payload = {
        IdOfficine: this.IdOfficine,
        Civilite: this.Civilite,
        NomPersonel: this.NomPersonel,
        PrenomPersonel: this.PrenomPersonel,
        AliasPersonel: this.AliasPersonel,
        DatNaisPersonel: this.DatNaisPersonel,
        PostPersonel: this.PostPersonel,
        TelPersonel: this.TelPersonel,
        AdrPersonel: this.AdrPersonel,
        EmailPersonel: this.EmailPersonel
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('personnel/createPersonnel', payload)
        .then((res) => {
          console.log(res)
          //this.popupFonction = false
          this.reset_data()
          this.getPersonnels()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupFonction = false
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        })
    },
    addCNIInfo () {
      const payload = {
        PersonnelId: this.PersonnelId,
        NumCniPersonel: this.NumCniPersonel,
        DateEmmissionCniPersonel: this.DateEmmissionCniPersonel,
        DateExpireCniPersonel: this.DateExpireCniPersonel
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('personnel/addCNIInfo', payload)
        .then((res) => {
          console.log(res)
          //this.popupFonction = false
          this.reset_data()
          this.getPersonnels()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupFonction = false
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        })
    },
    updateCniInfo () {

    },
    successUpload () {

    },
    uploadScanImage (data) {
      console.log(data)
    },
    update_avatar (e) {
      const file = e.target.files[0]
      this.CheminPhotoP = URL.createObjectURL(file)
    },
    reset_data () {
      this.IdOfficine = ''
      this.Civilite = ''
      this.NomPersonel = ''
      this.PrenomPersonel = ''
      this.AliasPersonel = ''
      this.DatNaisPersonel = ''
      this.PostPersonel =
      this.TelPersonel = ''
      this.AdrPersonel = ''
      this.EmailPersonel = ''
      this.PersonnelId = ''
      this.NumCniPersonel = ''
      this.DateEmmissionCniPersonel = ''
      this.DateExpireCniPersonel = ''
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then((resp) => {
          console.log(resp)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getPersonnels () {
      this.$store.dispatch('personnel/getPersonnels')
        .then((resp) => {
          resp.data.forEach((item) => {
            this.transiteObje = {
              id: item._id,
              personnelFullName: `${item.NomPersonel} ${item.PrenomPersonel}`
            }
            this.Personnels.push(this.transiteObje)
            this.transiteObje = {}
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created () {
    this.getOfficines()
    this.getPersonnels()
  }
}
</script>

<style scoped>

</style>
